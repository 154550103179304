import React from 'react'
import { createRoot } from 'react-dom/client'
import Widget from './Widget'
import { GatewayClient } from '../gateway/GatewayClient'

export default class EmbeddedWidget {
  static el: HTMLElement

  static mount() {
    const component = <Widget />

    function doRender() {
      if (EmbeddedWidget.el) {
        throw new Error('EmbeddedWidget is already mounted, unmount first')
      }
      const el = document.createElement('div')
      el.setAttribute('class', 'cleanslate')

      document.body.appendChild(el)

      createRoot(el).render(component)
      EmbeddedWidget.el = el
    }

    if (document.readyState === 'complete') {
      doRender()
    } else {
      window.addEventListener('load', () => {
        doRender()
      })
    }
  }

  // MPA などで、 mount 直後に後続のメソッドを連続で呼び出す場合はこちらを利用する
  static async mountSync() {
    const component = <Widget />

    const promise = new Promise(function doRender() {
      if (EmbeddedWidget.el) {
        throw new Error('EmbeddedWidget is already mounted, unmount first')
      }
      const el = document.createElement('div')
      el.setAttribute('class', 'cleanslate')

      document.body.appendChild(el)

      createRoot(el).render(component)
      EmbeddedWidget.el = el
    })

    window.addEventListener('DOMContentLoaded', async () => {
      await promise
    })
  }

  static async createClient(
    providerId: string,
    token: string,
    name: string,
  ): Promise<string> {
    const res = await GatewayClient.getInstance().createEmbedEnduser(
      providerId,
      token,
      name,
    )
    // @ts-expect-error
    return res.id
  }

  static async deleteClient(): Promise<any> {
    return await GatewayClient.getInstance().deleteEmbedEnduser()
  }

  static async authenticate(providerId: string, token: string): Promise<void> {
    try {
      await GatewayClient.getInstance().authenticate(providerId, token)
    } catch (e) {
      localStorage.removeItem('datable.token')
      localStorage.removeItem('datable.providerId')
      throw new Error('Not authenticated')
    }
    const iframe = document.getElementById(
      'datable-connect',
    ) as HTMLFrameElement
    if (iframe) {
      iframe.src = `${process.env.WIDGET_BASE_URL}/authentication?providerId=${providerId}&token=${token}`
    }
    const loaded = new Promise((resolve) => {
      iframe.onload = resolve
    })
    await loaded
    await new Promise((resolve) => self.setTimeout(resolve, 1000))
  }

  static async datasources(): Promise<any> {
    return await GatewayClient.getInstance().getDatasources()
  }

  static async templates(): Promise<any> {
    return await GatewayClient.getInstance().getTemplates()
  }

  static async connect(
    datasourceType: string,
    onClose: () => void,
  ): Promise<void> {
    const modal = document.getElementById('datable-connect-modal')
    const iframe = document.getElementById(
      'datable-connect',
    ) as HTMLFrameElement

    if (modal?.style.visibility === 'visible') {
      return
    }

    if (modal != null && iframe) {
      iframe.src = `${process.env.WIDGET_BASE_URL}/connection?datasourceType=${datasourceType}`
      iframe.style.width = '100%'
      iframe.style.height = '100%'
      modal.style.visibility = 'visible'

      const message = (event: MessageEvent): void => {
        if (event.origin !== process.env.WIDGET_BASE_URL) {
          return
        }
        if (event.data.type !== 'datable') {
          return
        }
        window.removeEventListener('message', message, false)
        modal.style.visibility = 'hidden'
        iframe.src = ''
        onClose()
      }
      window.addEventListener('message', message, false)
    }
  }

  static createIntegration(
    parameters: { [key: string]: string },
    onClose: (workflowId: string) => void,
    templateId: string,
  ): void {
    const modal = document.getElementById('datable-connect-modal')
    const iframe = document.getElementById(
      'datable-connect',
    ) as HTMLFrameElement

    if (modal?.style.visibility === 'visible') {
      return
    }

    if (modal != null && iframe) {
      iframe.src = `${
        process.env.WIDGET_BASE_URL
      }/customizedWorkflows/new?parameters=${window.btoa(
        JSON.stringify(parameters),
      )}&templateId=${templateId}`
      iframe.style.width = '100%'
      iframe.style.height = '100%'
      modal.style.visibility = 'visible'

      const message = (event: MessageEvent): void => {
        if (event.origin !== process.env.WIDGET_BASE_URL) {
          return
        }
        if (event.data.type !== 'datable') {
          return
        }
        window.removeEventListener('message', message, false)
        iframe.src = ''

        if (event.data.type !== undefined && event.data.message !== undefined) {
          onClose(event.data.message)
        }
        modal.style.visibility = 'hidden'
      }
      window.addEventListener('message', message, false)
    }
  }

  static updateIntegration(
    parameters: { [key: string]: string },
    onClose: (workflowId: string) => void,
    customizedWorkflowId: string,
  ): void {
    const modal = document.getElementById('datable-connect-modal')
    const iframe = document.getElementById(
      'datable-connect',
    ) as HTMLFrameElement

    if (modal?.style.visibility === 'visible') {
      return
    }

    if (modal != null && iframe) {
      iframe.src = `${
        process.env.WIDGET_BASE_URL
      }/customizedWorkflows/edit?parameters=${window.btoa(
        JSON.stringify(parameters),
      )}&customizedWorkflowId=${customizedWorkflowId}`
      iframe.style.width = '100%'
      iframe.style.height = '100%'
      modal.style.visibility = 'visible'

      const message = (event: MessageEvent): void => {
        if (event.origin !== process.env.WIDGET_BASE_URL) {
          return
        }
        if (event.data.type !== 'datable') {
          return
        }
        window.removeEventListener('message', message, false)
        iframe.src = ''

        if (event.data.type !== undefined && event.data.message !== undefined) {
          onClose(event.data.message)
        }
        modal.style.visibility = 'hidden'
      }
      window.addEventListener('message', message, false)
    }
  }

  static executionHistories(customizedWorkflowId: string): void {
    const modal = document.getElementById('datable-connect-modal')
    const iframe = document.getElementById(
      'datable-connect',
    ) as HTMLFrameElement

    if (modal?.style.visibility === 'visible') {
      return
    }

    if (modal != null && iframe) {
      iframe.src = `${process.env.WIDGET_BASE_URL}/executionHistories?customizedWorkflowId=${customizedWorkflowId}`
      iframe.style.width = '100%'
      iframe.style.height = '100%'
      modal.style.visibility = 'visible'

      const message = (event: MessageEvent): void => {
        if (event.origin !== process.env.WIDGET_BASE_URL) {
          return
        }
        if (event.data.type !== 'datable') {
          return
        }
        window.removeEventListener('message', message, false)
        iframe.src = ''
        modal.style.visibility = 'hidden'
      }
      window.addEventListener('message', message, false)
    }
  }

  static async deleteCustomizedWorkflow(id: string): Promise<any> {
    return await GatewayClient.getInstance().deleteCustomizedWorkflow(id)
  }

  static async deleteAllResourcesRelatedToEmbedEnduser(
    datasourceTypes: string[],
  ): Promise<any> {
    await Promise.all(
      datasourceTypes.map(async (datasourceType) => {
        return await GatewayClient.getInstance().deleteAllResourcesRelatedToEmbedEnduser(
          datasourceType,
        )
      }),
    )
  }

  static async runCustomizedWorkflow(id: string, params: any): Promise<any> {
    return await GatewayClient.getInstance().runCustomizedWorkflow(id, params)
  }

  static sentryTest() {
    throw new Error('Sentry test in sdk')
  }

  static openSentryTestModal() {
    const modal = document.getElementById('datable-connect-modal')
    const iframe = document.getElementById(
      'datable-connect',
    ) as HTMLFrameElement
    if (modal != null && iframe) {
      iframe.src = `${process.env.WIDGET_BASE_URL}/sentryTest`
      iframe.style.width = '100%'
      iframe.style.height = '100%'
      modal.style.visibility = 'visible'
    }
  }
}
