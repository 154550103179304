import { ApiClient } from './ApiClient'

export class GatewayClient {
  private static instance: GatewayClient

  public static getInstance(): GatewayClient {
    if (GatewayClient.instance === undefined) {
      GatewayClient.instance = new GatewayClient()
    }
    return GatewayClient.instance
  }

  // EmbedEnduser
  async createEmbedEnduser(
    providerId: string,
    token: string,
    name: string,
  ): Promise<string> {
    localStorage.setItem('datable.token', token)
    return await ApiClient.getInstance().post(
      `v1/providers/${providerId}/embedEndusers`,
      { name },
    )
  }

  async deleteEmbedEnduser(): Promise<string> {
    const providerId = localStorage.getItem('datable.providerId')
    if (providerId === null) {
      throw new Error('providerId not found')
    }
    return await ApiClient.getInstance().delete(
      `v1/providers/${providerId}/embedEndusers`,
    )
  }

  // Authenticate
  async authenticate(providerId: string, token: string): Promise<void> {
    localStorage.setItem('datable.token', token)
    localStorage.setItem('datable.providerId', providerId)

    await ApiClient.getInstance().post(
      `v1/providers/${providerId}/authentication`,
    )
  }

  // Datasource
  async getDatasources(): Promise<any> {
    const providerId = localStorage.getItem('datable.providerId')
    if (providerId === null) {
      throw new Error('providerId not found')
    }
    const res = await ApiClient.getInstance().get(
      `v1/providers/${providerId}/datasources`,
    )
    // @ts-expect-error
    return res.datasources
  }

  // Template
  async getTemplates(): Promise<any> {
    const providerId = localStorage.getItem('datable.providerId')
    if (providerId === null) {
      throw new Error('providerId not found')
    }
    const res = await ApiClient.getInstance().get(
      `v1/providers/${providerId}/templates`,
    )
    // @ts-expect-error
    return res.templates
  }

  // customizedWorkflow
  async deleteCustomizedWorkflow(customizedWorkflowId: string): Promise<void> {
    const providerId = localStorage.getItem('datable.providerId')
    if (providerId === null) {
      throw new Error('providerId not found')
    }

    await ApiClient.getInstance().delete(
      `v1/providers/${providerId}/customizedWorkflows/${customizedWorkflowId}`,
    )
  }

  async runCustomizedWorkflow(
    customizedWorkflowId: string,
    params: any,
  ): Promise<void> {
    const providerId = localStorage.getItem('datable.providerId')
    if (providerId === null) {
      throw new Error('providerId not found')
    }

    await ApiClient.getInstance().post(
      `v1/providers/${providerId}/customizedWorkflows/${customizedWorkflowId}/run`,
      params,
    )
  }

  async deleteAllResourcesRelatedToEmbedEnduser(
    datasourceType: string,
  ): Promise<string> {
    const providerId = localStorage.getItem('datable.providerId')
    if (providerId === null) {
      throw new Error('providerId not found')
    }
    return await ApiClient.getInstance().delete(
      `v1/providers/${providerId}/deleteAllResourcesRelatedToEmbedEnduser?datasourceType=${datasourceType}`,
    )
  }
}
