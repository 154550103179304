import axios from 'axios'

axios.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem('datable.token')
    if (token) {
      request.headers['Authorization'] = `Bearer ${token}`
    }
    return request
  },
  async function (error) {
    return await Promise.reject(error)
  },
)

export class ApiClient {
  private static instance: ApiClient
  readonly BASE_URL: string = process.env.GATEWAY_BASE_URL ?? 'localhost:8081'

  public static getInstance(): ApiClient {
    if (ApiClient.instance === undefined) {
      ApiClient.instance = new ApiClient()
    }
    return ApiClient.instance
  }

  async get<T, H>(path: string, headers?: H): Promise<T> {
    const { data, status } = await axios.get(`${this.BASE_URL}/${path}`, {
      headers: { ...headers },
    })
    if (status !== 200) {
      throw new Error(`Request failed with status code ${status}`)
    }
    return data
  }

  async post<T, P>(path: string, params?: P): Promise<T> {
    const { data, status } = await axios.post(
      `${this.BASE_URL}/${path}`,
      params,
    )
    if (status !== 200) {
      throw new Error(`Request failed with status code ${status}`)
    }
    return data
  }

  async delete<T>(path: string): Promise<T> {
    const { data, status } = await axios.delete(`${this.BASE_URL}/${path}`)
    if (status !== 204) {
      throw new Error(`Request failed with status code ${status}`)
    }
    return data
  }
}
