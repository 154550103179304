import React from 'react'
import { Main, ModalWindow, ModalOverlay } from './styles'

const Content = (): JSX.Element => {
  return (
    <>
      <ModalOverlay />
      <ModalWindow>
        <iframe
          id="datable-connect"
          title="datable connect modal"
          src=""
          style={{ border: 'none' }}
        ></iframe>
      </ModalWindow>
    </>
  )
}

const Widget = (): JSX.Element => (
  <Main id="datable-connect-modal">
    <Content />
  </Main>
)

export default Widget
